@import url('https://fonts.googleapis.com/css?family=Sulphur+Point&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#root {
  overflow: auto;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  /* background: #0c0f13; */
  overflow: hidden;
  font-family: 'Sulphur Point', sans-serif;
  color: white;
  font-size: 0.9rem;
}
