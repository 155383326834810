.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bigWrap {
  margin: 0 auto;
  position: relative;
  width:100%;
  height:100%;
  z-index:5;
  /* background-color: rgba(253, 236, 54,0.1); */
  background-color: rgba(0, 0, 0, 1);
}

.topWrap {
  position: absolute;
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-gap: 10%; */
  /* padding: 10px; */
  justify-content: space-evenly;
  width:100%;
  height:10%;
  bottom: 4%;
  z-index:6;
}

.logoImage {
  height: auto;
  width: 60vw;
  /* margin-top: 30vh; */
}

.iconImage {
  height: auto;
  width: 3vw;
  /* margin-top: 30vh; */
}

.iconImage2 {
  height: auto;
  width: 2vw;
  /* margin-top: 30vh; */
}

.logoWrap {
  position: absolute;
  display: grid;
  /* grid-template-columns: auto auto auto; */
  /* grid-gap: 10%; */
  /* padding: 10px; */
  justify-content: space-evenly;
  width:100%;
  height:10%;
  top: 10%;
  z-index:6;
}

.topWrap a {
  padding: 1rem 1rem;
  color: #f2ae91;
  text-decoration: none;
  font-size: 1.7rem;
  letter-spacing: .7rem;
}


@keyframes spin{
  0%{transform:rotate(0deg)}
  100%{transform:rotate(360deg)}
}

@media only screen and (max-width: 500px) {
  .topWrap a {
    font-size: 0.8rem;
	letter-spacing: .02rem;
  }
  .socialIcons {
	  position: relative;
	  bottom: 50%;
  }
  .iconImage {
    height: auto;
    width: 7vw;
    /* margin-top: 30vh; */
  }
  .iconImage2 {
    height: auto;
    width: 6vw;
    /* margin-top: 30vh; */
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


 .h {
	 /* border: 2px solid rgba(255, 255, 255, .1); */
	 border-radius: 400px;
	 position: absolute;
	 margin: auto;
}
 .h:nth-of-type(1) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 6px;
	 height: 1px;
	 right: 10px;
	 bottom: 10px;
	 -webkit-filter: blur(8.3333333333px);
	 -moz-filter: blur(8.3333333333px);
	 -o-filter: blur(8.3333333333px);
	 -ms-filter: blur(8.3333333333px);
	 transform-origin: 4px 2px;
	 animation: spin 3s 0.1s linear infinite;
	 background: rgba(93, 34, 205, 0.9875);
}
 .h:nth-of-type(2) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 12px;
	 height: 2px;
	 right: 20px;
	 bottom: 20px;
	 -webkit-filter: blur(8.6666666667px);
	 -moz-filter: blur(8.6666666667px);
	 -o-filter: blur(8.6666666667px);
	 -ms-filter: blur(8.6666666667px);
	 transform-origin: 8px 4px;
	 animation: spin 3s 0.2s linear infinite;
	 background: rgba(96, 35, 205, 0.975);
}
 .h:nth-of-type(3) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 18px;
	 height: 3px;
	 right: 30px;
	 bottom: 30px;
	 -webkit-filter: blur(9px);
	 -moz-filter: blur(9px);
	 -o-filter: blur(9px);
	 -ms-filter: blur(9px);
	 transform-origin: 12px 6px;
	 animation: spin 3s 0.3s linear infinite;
	 background: rgba(99, 36, 205, 0.9625);
}
 .h:nth-of-type(4) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 24px;
	 height: 4px;
	 right: 40px;
	 bottom: 40px;
	 -webkit-filter: blur(9.3333333333px);
	 -moz-filter: blur(9.3333333333px);
	 -o-filter: blur(9.3333333333px);
	 -ms-filter: blur(9.3333333333px);
	 transform-origin: 16px 8px;
	 animation: spin 3s 0.4s linear infinite;
	 background: rgba(102, 37, 205, 0.95);
}
 .h:nth-of-type(5) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 30px;
	 height: 5px;
	 right: 50px;
	 bottom: 50px;
	 -webkit-filter: blur(9.6666666667px);
	 -moz-filter: blur(9.6666666667px);
	 -o-filter: blur(9.6666666667px);
	 -ms-filter: blur(9.6666666667px);
	 transform-origin: 20px 10px;
	 animation: spin 3s 0.5s linear infinite;
	 background: rgba(105, 38, 205, 0.9375);
}
 .h:nth-of-type(6) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 36px;
	 height: 6px;
	 right: 60px;
	 bottom: 60px;
	 -webkit-filter: blur(10px);
	 -moz-filter: blur(10px);
	 -o-filter: blur(10px);
	 -ms-filter: blur(10px);
	 transform-origin: 24px 12px;
	 animation: spin 3s 0.6s linear infinite;
	 background: rgba(108, 39, 205, 0.925);
}
 .h:nth-of-type(7) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 42px;
	 height: 7px;
	 right: 70px;
	 bottom: 70px;
	 -webkit-filter: blur(10.3333333333px);
	 -moz-filter: blur(10.3333333333px);
	 -o-filter: blur(10.3333333333px);
	 -ms-filter: blur(10.3333333333px);
	 transform-origin: 28px 14px;
	 animation: spin 3s 0.7s linear infinite;
	 background: rgba(111, 40, 205, 0.9125);
}
 .h:nth-of-type(8) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 48px;
	 height: 8px;
	 right: 80px;
	 bottom: 80px;
	 -webkit-filter: blur(10.6666666667px);
	 -moz-filter: blur(10.6666666667px);
	 -o-filter: blur(10.6666666667px);
	 -ms-filter: blur(10.6666666667px);
	 transform-origin: 32px 16px;
	 animation: spin 3s 0.8s linear infinite;
	 background: rgba(114, 41, 205, 0.9);
}
 .h:nth-of-type(9) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 54px;
	 height: 9px;
	 right: 90px;
	 bottom: 90px;
	 -webkit-filter: blur(11px);
	 -moz-filter: blur(11px);
	 -o-filter: blur(11px);
	 -ms-filter: blur(11px);
	 transform-origin: 36px 18px;
	 animation: spin 3s 0.9s linear infinite;
	 background: rgba(117, 42, 205, 0.8875);
}
 .h:nth-of-type(10) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 60px;
	 height: 10px;
	 right: 100px;
	 bottom: 100px;
	 -webkit-filter: blur(11.3333333333px);
	 -moz-filter: blur(11.3333333333px);
	 -o-filter: blur(11.3333333333px);
	 -ms-filter: blur(11.3333333333px);
	 transform-origin: 40px 20px;
	 animation: spin 3s 1s linear infinite;
	 background: rgba(120, 43, 205, 0.875);
}
 .h:nth-of-type(11) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 66px;
	 height: 11px;
	 right: 110px;
	 bottom: 110px;
	 -webkit-filter: blur(11.6666666667px);
	 -moz-filter: blur(11.6666666667px);
	 -o-filter: blur(11.6666666667px);
	 -ms-filter: blur(11.6666666667px);
	 transform-origin: 44px 22px;
	 animation: spin 3s 1.1s linear infinite;
	 background: rgba(123, 44, 205, 0.8625);
}
 .h:nth-of-type(12) {
	 box-shadow: 3px 0px rgba(255, 255, 255, ), 6px -5px rgba(10, 0, 0, );
	 width: 72px;
	 height: 12px;
	 right: 120px;
	 bottom: 120px;
	 -webkit-filter: blur(12px);
	 -moz-filter: blur(12px);
	 -o-filter: blur(12px);
	 -ms-filter: blur(12px);
	 transform-origin: 48px 24px;
	 animation: spin 3s 1.2s linear infinite;
	 background: rgba(126, 45, 205, 0.85);
}

 @keyframes spin {
	 0% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(360deg);
	}
}
 
